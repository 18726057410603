// Color
$imperial-gold: rgb(205, 174, 124);
$pure-white: rgb(255, 255, 255);
$light-white: rgb(248, 248, 248);
$dark-pantone: #13273d;
$deep-black: rgb(19, 23, 31);
$light-black: rgb(63, 68, 84);
$bright-red: rgb(252, 49, 28);
$light-grey: rgb(247, 249, 251);
$light-green: #19bc9c;
$blue: #03a9f3;
$royal-blue: #003453;
$snow-flurry: #eaffc9;
$yale-blue: #2d5b8f;
$success-green: #89c13e;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Add the standard height of element at here
$element-height: (
  "dropdown": 36px,
  "button": 36px,
)
